* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #073425;
  color: #fafaf9;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  padding: 50px 64px;
}

h1 {
  font-size: 40px;
  text-transform: uppercase;
  font-family: "Crimson Text", serif;
  color: rgb(130, 205, 205);
  color: #fafaf9;
  text-align: center;
  line-height: 1;
  margin-top: 1px;
}

.story {
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 20px;
  color: #fafaf9;
  background-color: #264935;
  padding: 20px 30px;

  border-radius: 10px;
  display: flex;
  align-items: center;
  letter-spacing: 3px;
  gap: 20px;
}

.category {
  text-transform: uppercase;
  font-size: 15px;
  color: #fafaf9;
  padding: 3px 10px;
  border-radius: 15px;
}

ul {
  list-style: none;
  font-family: "Crimson Text", serif;
}
.header {
  margin-bottom: 40px;

  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerimages {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
}
.buttons-like-dislike {
  margin-left: auto;
  flex-shrink: 0;
  display: flex;
  gap: 8px;
}

.main {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 50px;

  height: calc(100vh - 68px - 40px - 50px - 50px);
}

#root {
  max-width: 1500px;

  margin: 0 auto;
}

.logo image {
  height: 68px;
  width: 68px;
}

section {
  overflow: scroll;
  padding-bottom: 40px;
}

.building {
  color: #686d69;
  text-decoration: none;
  margin-left: 10px;
  transition: 0.3s;
}
.building:hover {
  color: #ddfbe3;
}

/* .source:link,
.source:visited {
  color: #a9a9a9;
  text-decoration: none;
  margin-left: 10px;
  transition: 0.3s;
}

.source:hover {
  color: rgb(15, 154, 52);
}

.source:active {
  color: blue;
} */

.btn {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1;
  text-transform: uppercase;
  font-size: 17px;
  padding: 16px 0 13px;
  background-image: linear-gradient(180deg, rgb(36 125 94), #0e6245);
  border-radius: 10px;
  border: none;
  color: inherit;
}

.btn:hover {
  background-image: linear-gradient(180deg, rgb(39, 136, 102), #116e4d);
  transform: scale(100%);
  cursor: pointer;
}

.btn:active {
  background-image: linear-gradient(180deg, rgb(31, 108, 81), #0d563d);
}

.btn-large {
  font-size: 20px;
  padding: 20px 30px 20px;
}

.btn-all-categories {
  margin-bottom: 16px;
  width: 100%;
}

.btn-category {
  width: 100%;
  background-image: none;
}

.category2 {
  margin-bottom: 10px;
}

.buttons-like-dislike button {
  border: none;
  background-color: #355c48;
  font-size: 18px;
  padding: 6px 12px;
  border-radius: 100px;
  color: inherit;
  font-family: inherit;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
}

.buttons-like-dislike button:hover {
  background-color: rgb(132, 127, 127);
  background-color: #013d29;
  color: #ffffff;
}

.buttons-like-dislike button:disabled {
  background-color: #264935;
}

.story-form {
  margin-bottom: 40px;
  background-color: #37493c;
  padding: 15px 30px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
}
.story-form input,
.story-form select {
  background-color: #455c54;
  border: none;
  border-radius: 100px;
  padding: 15px;
  font-size: 17px;
  color: inherit;
  font-family: inherit;
}

.story-form input::placeholder {
  color: rgb(106, 140, 112);
}

.story-form {
  font-weight: 500;
  font-size: 15px;
  margin-right: 18px;
}

.story-form input:first-child {
  flex-grow: 1;
}

@media (max-width: 1200px) {
  .story {
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (max-width: 1000px) {
  body {
    padding: 32px 48px;
  }
  .story-form {
    flex-direction: column;
    align-items: stretch;
  }
  .story-form input,
  .story-form select {
    width: auto;
  }
}

@media (max-width: 900px) {
  .main {
    grid-template-columns: 1fr;
  }
}
.loading {
  text-align: center;

  font-size: 30px;
  font-weight: 500;
  margin-top: 30px;
}

.hidden {
  display: none !important;
}

@media (max-width: 900px) {
  .main {
    grid-template-columns: 1fr;
    height: auto;
  }
}

@media (max-width: 600px) {
  #root {
    padding: 24px;
  }

  .header {
    flex-direction: column;
    gap: 16px;
  }

  .fact-form {
    padding: 16px;
  }

  .fact {
    padding: 12px 16px;
  }
}
